import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import "./index.scss"

import VideoBg from "../videos/stk-praha-intro.mp4"

const IndexPage = () => (

	<Layout>
		<Seo title="STK Praha - sportovní taneční klub" />

		<div className="stk-hero">

			<div className="stk-hero__content">
				<div className="stk-container stk-container--hero">
				<h1 className="stk-hero__header">STK Praha<br />sportovní taneční klub</h1>
				<p className="stk-hero__subheader">Jsme přední pražský taneční klub s&nbsp;historií od&nbsp;roku&nbsp;1928</p>
				<div>
					<Link className="stk-button stk-button--big stk-button--homepage" to="/kontakt">
						Kontakt
					</Link>
					<Link className="stk-button stk-button--big stk-button--homepage" to="/rozvrh-tanecnich-salu">
						Aktuální rozvrh
					</Link>
				</div>
				</div>
			</div>

			<video autoPlay="autoplay" muted loop className="stk-hero__video">
				<source src={VideoBg} type="video/mp4" />
			</video>

		</div>

	</Layout>
)

export default IndexPage